import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import LanguageIcon from '@mui/icons-material/Language'
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop'
import StorefrontIcon from '@mui/icons-material/Storefront'
import { Badge, Box, Button, ButtonGroup, Grid, IconButton, Link, Stack, SvgIcon, Tooltip } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import LoopIcon from '@src/assets/loop.svg?react'
import { useSideEffect } from '@src/data/store/effects/side-effects'
import { reportErrorToConsole } from '@src/services/error-logger'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { appActions } from '@src/data/store/AppStore'
import { get } from 'lodash-es'
import LanguageProvinceIndicator from '../../../components/LanguageProvinceIndicator'
import { useAppDispatch, useAppSelector } from '../../../data/store'
import { creditEffects, creditSelectors } from '../../../data/store/CreditApplication'
import { personalLoanworksheetSelectors } from '../../../data/store/PersonalLoanWorksheet'
import {
  Constants,
  CreditApplication,
  CreditApplicationMessage,
  EBeneficiaryType,
  ECreditApplicationStatus,
  EFinancingProgram,
  ELoanPurpose,
  EOriginSystemId,
  EProgressionStatus,
  User,
} from '../../../data/types'
import { Merchant } from '../../../data/types/Merchant'
import { formatDate } from '../../../services/Formatter'
import CopyLoanAsDraftBtn from './CopyLoanAsDraftBtn'
import PhoneNumber from './PhoneNumber'

type Props = {
  creditApplication: CreditApplication
  creditApplicationMessage: CreditApplicationMessage[]
  merchant: Merchant
  onStatusChange: () => void
  onMerchantChanged: () => void
  creditApplicationCanBeLocked: boolean
  loanId: number | undefined | null
  user: User | null
  isAbleSendFundDepositConfirmation: boolean
  applicationStatus: EProgressionStatus
  editDisabled: boolean
  finalDecisionIsPending: boolean
  allTasksRequiredForPayoutCompleted: boolean
  allDocumentsRequiredForPayOutCompleted: boolean
  hasDocumentsPendingApproval: boolean
}

const ApplicationResume = ({
  creditApplication,
  creditApplicationMessage,
  merchant,
  onStatusChange,
  onMerchantChanged,
  creditApplicationCanBeLocked,
  loanId,
  user,
  isAbleSendFundDepositConfirmation,
  applicationStatus,
  editDisabled,
  finalDecisionIsPending,
  allTasksRequiredForPayoutCompleted,
  allDocumentsRequiredForPayOutCompleted,
  hasDocumentsPendingApproval,
}: Props) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }
  const renderCancelButtonText = useMemo(() => {
    if (creditApplication.status !== ECreditApplicationStatus.Cancelled) return t('common.cancel')
    return t('common.reactivate')
  }, [creditApplication.status, t])

  const financingProgramConfig = get(
    Constants.financingProgramConfigs,
    creditApplication.financingProgramId?.toLowerCase() ?? 'default',
    Constants.financingProgramConfigs.default,
  )

  const dispatchEffect = useSideEffect()
  const handleLockingCreditApplication = useCallback(() => {
    if (creditApplication?.id) {
      if (!creditApplication.editLocked) {
        dispatchEffect(
          creditEffects.lockCreditApplication({
            creditApplicationId: creditApplication.id,
          }),
        ).catch(reportErrorToConsole)
      } else {
        dispatchEffect(
          creditEffects.unlockCreditApplication({
            creditApplicationId: creditApplication.id,
          }),
        ).catch(reportErrorToConsole)
      }
    }
  }, [creditApplication?.id, creditApplication?.editLocked, dispatchEffect])

  const onHandlePayOut = useCallback(() => {
    if (creditApplication?.id) {
      dispatchEffect(creditEffects.payOut({ creditApplicationId: creditApplication.id })).catch(reportErrorToConsole)
    }
  }, [creditApplication?.id, dispatchEffect])

  const onHandleFundDepositConfirmation = useCallback(() => {
    if (creditApplication?.id) {
      dispatchEffect(
        creditEffects.sendFundDepositConfirmation({
          creditApplicationId: creditApplication.id,
          financingProgramId: creditApplication.financingProgramId,
        }),
      ).catch(reportErrorToConsole)
    }
  }, [creditApplication?.id, creditApplication?.financingProgramId, dispatchEffect])

  const [loanLoading, setLoanLoading] = React.useState(false)
  const handleCreateLoan = useCallback(() => {
    if (!creditApplication?.loanId) {
      if (!loanLoading) {
        setLoanLoading(true)
        dispatchEffect(
          creditEffects.createLoan({
            financingProgramId: creditApplication.financingProgramId,
            id: creditApplication.id,
          }),
        )
          .catch((handleLoanError) => {
            reportErrorToConsole(handleLoanError)
            if (!financingProgramConfig.useCreateAlisLoan) {
              dispatch(appActions.setBusinessErrors([{ message: 'editCreditApplication.errors.createLoan' }]))
            }
          })
          .finally(() => {
            setLoanLoading(false)
          })
      }
    }
  }, [
    creditApplication?.loanId,
    creditApplication?.financingProgramId,
    creditApplication?.id,
    dispatchEffect,
    loanLoading,
    dispatch,
    financingProgramConfig.useCreateAlisLoan,
  ])

  const handleViewLoan = useCallback(() => {
    window.open(
      `${process.env.REACT_APP_MONOLITH_API_URL}/admin/ppa/customer.aspx?id=${creditApplication?.loanId}`,
      '_blank',
    )
  }, [creditApplication?.loanId])

  const applicationIsDraft = creditApplication.status === ECreditApplicationStatus.Draft

  const canCreateLoan =
    (creditApplication.financingProgramId === EFinancingProgram.AutoRsla ||
      (creditApplication.financingProgramId !== EFinancingProgram.Auto && creditApplicationCanBeLocked) ||
      creditApplication.editLocked) &&
    !creditApplication.loanId &&
    user?.rights.canCreateLoan &&
    creditApplication.cvtNumber &&
    creditApplication.paymentBankAccount &&
    !finalDecisionIsPending &&
    allTasksRequiredForPayoutCompleted &&
    allDocumentsRequiredForPayOutCompleted &&
    !hasDocumentsPendingApproval

  const createdWithPromotion = useAppSelector(creditSelectors.createdWithPromotion)
  const hasConfirmedPromotion = useAppSelector(personalLoanworksheetSelectors.hasConfirmedPromotion)

  const isSwapMerchantDisabled = createdWithPromotion || hasConfirmedPromotion

  function getBeneficiaryName() {
    const {
      beneficiaryTypeId,
      applicant,
      coApplicant,
      otherBeneficiaryFirstName,
      otherBeneficiaryLastName,
      loanPurposeId,
    } = creditApplication

    switch (beneficiaryTypeId) {
      case EBeneficiaryType.Applicant:
        return `${applicant?.firstName} ${applicant?.lastName}`
      case EBeneficiaryType.Coapplicant:
        return `${coApplicant?.firstName} ${coApplicant?.lastName}`
      case EBeneficiaryType.Other:
        if (loanPurposeId === ELoanPurpose.Veterinary) {
          return `${otherBeneficiaryLastName}`
        }
        if (otherBeneficiaryFirstName === null || otherBeneficiaryLastName === null) {
          return ''
        }
        return `${otherBeneficiaryFirstName} ${otherBeneficiaryLastName}`
      default:
        return ''
    }
  }

  const isMerchandStateIso =
    creditApplication.financingProgramId === EFinancingProgram.Auto ||
    creditApplication.financingProgramId === EFinancingProgram.Vlvr ||
    creditApplication.originSystemId === EOriginSystemId.MerchantDashboard

  return (
    <Grid container item xs={12} spacing={2} sx={{ mb: 2 }}>
      <Grid item xs={12} md={12}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <span>
            <Typography variant="h6" component="div">
              {t('common.application')} : #{creditApplication.referenceNumber}
              {creditApplication?.parentCreditApplicationId && (
                <>
                  {' ('}
                  {t('credit.refinancing')}{' '}
                  <Link
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`/Applications/${creditApplication.financingProgramId}/${creditApplication.parentCreditApplicationId}/view`}
                  >
                    {creditApplication.parentReferenceNumber}
                  </Link>
                  {' )'}
                </>
              )}
            </Typography>

            {financingProgramConfig.useCreateAlisLoan && creditApplication.loanId && (
              <Typography variant="h6" component="div">
                {t('common.loan')} : #{creditApplication.loanId}
              </Typography>
            )}
            {creditApplication.financingProgramId === EFinancingProgram.Auto && (
              <Typography variant="h6" component="div" gutterBottom>
                #DT: #TODO
              </Typography>
            )}
          </span>
          {creditApplication.financingProgramId === EFinancingProgram.Auto && (
            <IconButton disabled={applicationIsDraft} aria-label="Ice-center" color="primary">
              <LanguageIcon fontSize="large" />
            </IconButton>
          )}
          <Typography variant="body1" component="div" sx={{ border: '1px solid', borderRadius: 2, p: 1 }}>
            {t('editCreditApplication.beneficiary.beneficiary')}: {getBeneficiaryName()}
          </Typography>
          <Typography variant="body1" component="div" sx={{ border: '1px solid', borderRadius: 2, p: 1 }}>
            {t('common.program')} : {t(`enum.eFinancingProgram.${creditApplication.financingProgramId}`)}
          </Typography>
          {creditApplication.financingProgramId === EFinancingProgram.Auto && (
            <Badge badgeContent={creditApplicationMessage.length} color="success">
              <Button
                variant="outlined"
                size="large"
                onClick={() =>
                  openInNewTab(`/Applications/${creditApplication.financingProgramId}/${creditApplication.id}/messages`)
                }
              >
                {t('message.message', '', { count: creditApplicationMessage.length })}
              </Button>
            </Badge>
          )}
          {creditApplication.financingProgramId === EFinancingProgram.Auto && (
            <Badge color="success">
              <Button
                variant="outlined"
                size="large"
                disabled={
                  !(creditApplicationCanBeLocked && user?.rights.canEditCreditApp && user?.isInternal && !editDisabled)
                }
                onClick={handleLockingCreditApplication}
              >
                {creditApplication.editLocked ? t('lockCredit.unlockCredit') : t('lockCredit.lockCredit')}
              </Button>
            </Badge>
          )}

          <div>
            {isMerchandStateIso && <StorefrontIcon fontSize="large" color="primary" />}

            <LanguageProvinceIndicator
              correspondanceLanguageId={creditApplication.correspondanceLanguageId}
              applicantStateIso={
                isMerchandStateIso ? merchant.province : creditApplication.applicant.currentAddress.stateIso ?? null
              }
            />
          </div>
        </Stack>
      </Grid>
      <Grid item xs={12} md={8}>
        <Box sx={{ border: '1px solid grey', borderRadius: 2, p: 1 }}>
          <Stack direction="row" alignItems="flex-end" justifyContent="space-between">
            <span>
              <Typography>
                {t('common.origin')} : {t(`enum.eOriginSystemId.${creditApplication.originSystemId}`)}
              </Typography>
              {creditApplication.financingProgramId === EFinancingProgram.Personal && (
                <Typography variant="body1" component="div">
                  {t('editCreditApplication.loanPurpose')} :{' '}
                  {t(`enum.eLoanPurposes.${creditApplication.loanPurposeId}`)}
                </Typography>
              )}
              <Typography variant="body1" component="div">
                {t('common.status')} :{' '}
                {applicationIsDraft
                  ? t('enum.eCreditApplicationStatus.draft')
                  : t(`enum.eProgressionStatusAbbr.${applicationStatus}`)}
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                {t('common.createdOn')} : {formatDate(creditApplication.createdOn)}
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                {t('common.expiresOn')} : {formatDate(creditApplication.expiresOn)}
              </Typography>
            </span>
            <span>
              {creditApplication.financingProgramId === EFinancingProgram.Personal && (
                <div>
                  <Typography variant="body1" component="div">
                    {t('editCreditApplication.amountRequested')} : {creditApplication.requestedLoanAmount ?? 0} $
                  </Typography>
                  <Typography variant="body1" component="div">
                    {t('editCreditApplication.amountGranted')} :{' '}
                    {creditApplication?.finalCreditDecision?.maxAmountFinanced ?? 0} $
                  </Typography>
                  <Typography variant="body1" component="div">
                    {t('editCreditApplication.maximumLoanAmount')} :{' '}
                    {creditApplication?.finalCreditDecision?.maximumAvalaibleAmount ?? 0} $
                  </Typography>
                </div>
              )}
              <Typography variant="body1" component="div">
                {t('common.merchant')} : {merchant?.name}
              </Typography>
              <Typography variant="body1" component="div" gutterBottom>
                {t('common.by')} : {creditApplication.createdByUserFullname}
              </Typography>
              <PhoneNumber phone={merchant?.phone} applyPhoneFormatting={false} />
            </span>
            <span>
              {creditApplication.financingProgramId === EFinancingProgram.Personal && (
                <IconButton
                  color="success"
                  onClick={() =>
                    navigator.clipboard.writeText(
                      `${process.env.REACT_APP_SUBCRIPTIONIFINANCE_URL}/creditApplication/${creditApplication?.id}`,
                    )
                  }
                >
                  <SvgIcon fontSize="large">
                    <ContentCopyIcon />
                  </SvgIcon>
                </IconButton>
              )}
              <Tooltip placement="top" title={isSwapMerchantDisabled ? t('common.merchantSwapDisabled') : ''}>
                <span>
                  <IconButton
                    aria-label="swap"
                    onClick={onMerchantChanged}
                    disabled={!user?.rights.canChangeMerchant || editDisabled || isSwapMerchantDisabled}
                  >
                    <SvgIcon fontSize="large">
                      <LoopIcon />
                    </SvgIcon>
                  </IconButton>
                </span>
              </Tooltip>
            </span>
          </Stack>
        </Box>
      </Grid>
      <Grid item container xs={12} md={4} spacing={2}>
        <Grid item xs>
          <Stack direction="column" justifyContent="space-between" height="100%" spacing={1}>
            <ButtonGroup variant="outlined" size="large">
              {creditApplication.financingProgramId === EFinancingProgram.Auto && (
                <Button
                  fullWidth
                  size="large"
                  onClick={onHandlePayOut}
                  disabled={!user?.rights.canSetDisbursed || applicationIsDraft || editDisabled}
                >
                  {t('common.funding')}
                </Button>
              )}
              {creditApplication.financingProgramId === EFinancingProgram.Personal && (
                <Button
                  fullWidth
                  size="large"
                  onClick={onHandleFundDepositConfirmation}
                  disabled={!(user?.rights.canSetDisbursed && isAbleSendFundDepositConfirmation && !editDisabled)}
                >
                  {t('common.funding')}
                </Button>
              )}
              <Button disabled={applicationIsDraft} size="small">
                <LocalPrintshopIcon fontSize="large" />
              </Button>
            </ButtonGroup>
            <CopyLoanAsDraftBtn
              creditApplicationId={creditApplication.id}
              financingProgramId={creditApplication.financingProgramId}
            />
          </Stack>
        </Grid>
        <Grid item xs>
          <Stack direction="column" justifyContent="space-between" alignItems="flex-center" height="100%" spacing={1}>
            {!loanId && (
              <Button
                variant="outlined"
                size="large"
                disabled={!(canCreateLoan && !editDisabled && !loanLoading)}
                onClick={handleCreateLoan}
              >
                {t('common.createLoan')}
                {loanLoading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Button>
            )}
            {loanId && (
              <Button
                variant="outlined"
                size="large"
                disabled={financingProgramConfig.useCreateAlisLoan}
                onClick={handleViewLoan}
              >
                {t('common.consultLoan')}
              </Button>
            )}
            <Button
              variant="outlined"
              size="large"
              onClick={onStatusChange}
              disabled={!(user?.rights.canEditCreditApp && !editDisabled)}
            >
              {renderCancelButtonText}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ApplicationResume
